//图片列表
export const publicImageListCols = [
  {
    prop: 'merge',
    label: '图片',
    minWidth: '150',
    slotName: 'mergeSlot'
  },
  {
    prop: 'sizeMerge',
    label: '格式',
    minWidth: '150',
    slotName: 'sizeMergeSlot'
  },
  {
    prop: 'disableSlot',
    label: '状态',
    minWidth: '150',
    slotName: 'disableSlot'
  },
  {
    prop: 'isTort',
    label: '是否侵权',
    minWidth: '150',
    slotName: 'tortSlot'
  },
  {
    prop: 'categoryName',
    label: '图片分类',
    slotName: 'categoryNameSlot'
  },
  {
    prop: 'createByName',
    label: '设计师',
    value: 'manager'
  },
  {
    prop: 'createTime',
    label: '时间',
    align: 'center',
    slotName: 'createTimeSlot',
    minWidth: 100,
    sortable: true
  }
]
